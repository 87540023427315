// 自定义的按时段查看的表表格
<template>
  <div class="custom_table_with_time">
    <!-- 视图部分 -->
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <div class="custom_table_with_time_content" :key="parseInt(new Date())">
          <!--    stripe 斑马纹    -->
          <el-table border :data="tableArr" :max-height="maxHeight" :cell-style="rowStyle">
            <el-table-column prop="timeInterval" align="center" label="时段" min-width="12.5%"></el-table-column>

            <!-- 天的数据  采用自定义表头方式来实现 -->
            <el-table-column v-for="(day, index) in headArr" :key="index" align="center" min-width="12.5%">
              <!-- 自定义表头 -->
              <template slot="header">
                <div>{{ day }}</div>
              </template>

              <!-- 自定义表格内容 -->
              <template slot-scope="scope">
                <div class="custom_tab_content">
                  <div
                    :class="[
                      'item_table',
                      obj.state == 1 ? 'has_bg' : 'no_bg',
                      {
                        has_bom: pos != scope.row.data[index].classRoomInfo.length - 1,
                      },
                    ]"
                    v-for="(obj, pos) in scope.row.data[index].classRoomInfo"
                    :key="pos"
                  >
                    <div class="one_line l_h margin_top_4">教室：{{ obj.roomName }}</div>
                    <div class="one_line l_h" :title="obj.className">班级：{{ obj.className }}</div>
                    <div class="one_line l_h" :title="obj.curriculumName">班级课程：{{ obj.curriculumName }}</div>
                    <div class="l_h">
                      <span class="l_h">班级人数：</span>
                      <span :class="['l_h', obj.classStuNum == obj.classTotalNum ? 'red_text' : 'green_text']">{{ obj.classNum }}</span
                      >/{{ obj.planNum }}
                    </div>
                    <div class="one_line margin_bom_4 l_h">老师：{{ obj.teacherName }}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <!-- 标识部分 -->
          <div class="bom_info" v-if="tableArr && tableArr.length > 0">
            <div class="flex_1"></div>
            <div class="com_dv color_1 margin_right_10"></div>
            <div>已排已上</div>
            <div class="com_dv color_2 margin_left_30 margin_right_10"></div>
            <div>已排未上</div>
            <!-- <div class="com_dv color_3 margin_left_30 margin_right_10"></div>
            <div class="margin_right_20">不可排</div> -->
          </div>
        </div>
      </template>
    </Layout>
  </div>
</template>

<script>
//导出表格的操作
import { export_json_to_excel } from "@/Excel/Export2Excel";
export default {
  data() {
    return {
      //加载框
      showLoading: -1,

      //表头的数据
      headArr: [],

      //表格数据
      tableArr: [],

      //表给的最大高度
      maxHeight: 0,

      //导出表格时的表头
      exportHeadArr: [],

      //导出表格的表头
      tHeaderArr: ["星期", "时段", "教室名称", "班级名称", "班级课程", "班级人数", "老师", "状态"],

      //导出表格的字段
      tBodyValue: ["weekDay", "timeName", "roomName", "className", "curriculumName", "num", "teacherName", "stateValue"],

      //导出的表格数组
      exportArr: [],
    };
  },

  created() {
    //初始化表格的最大高度
    this.initHeihgt();

    //请求数据
    this.httpData();
  },

  methods: {
    //初始化表格
    initHeihgt() {
      let clientH = document.documentElement.clientHeight;
      this.maxHeight = clientH - 76 - 50 - 50 - 130;
    },

    //动态设置单元格的背景颜色 设置单个单元格样式   行下标：rowIndex    列下标：columnIndex,注意这里从0开始
    rowStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex != 0) {
        //根据当前单元格时候可以进行排课 设置单元格的背景
        if (row.data[columnIndex - 1].state == 1) {
          //可排
          // return "background-color: #A0FEA0";
        } else {
          //不可排
          // return "background-color:#CFD2CF";
        }
      }
    },

    //请求服务器数据
    //intervalType 1按时段 2按教室
    httpData() {
      let param = this.getHttpParams();
      param.type = 4;
      param.startTime = this.startTime;
      param.intervalType = "1";
      this.$http
        .fetchPost(this.$api.LESSONMANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let result = res.data;
          if (result.state == 200) {
            //获取表头的数据
            let serveData = res.data.data;

            //先清空表头和表格的内容
            this.headArr.splice(0, this.headArr.length);
            this.tableArr.splice(0, this.tableArr.length);

            //先清空一下导出时的表头信息
            this.exportHeadArr.splice(0, this.exportHeadArr.length);
            this.exportHeadArr.push("时段");

            if (!this.isEmpty(serveData)) {
              //表头数据
              serveData.forEach((item) => {
                //创建表头
                this.headArr.push(item.weekDay);

                this.exportHeadArr.push(item.weekDay);
              });

              //创建表格数据
              let initTabArr = [];
              let list = serveData[0].list;
              if (!this.isEmpty(list)) {
                list.forEach((o) => {
                  //时段信息
                  let tabObj = new Object();
                  tabObj.timeInterval = o.timeName;
                  initTabArr.push(tabObj);
                });

                for (let i = 0; i < initTabArr.length; i++) {
                  let tabObj = initTabArr[i];
                  //组装每个对象的7天数据
                  let data = [];
                  serveData.forEach((k) => {
                    let dataObj = new Object();
                    //周一
                    dataObj.dayValue = k.weekDay;
                    //单元格状态
                    //单元格的教室信息
                    dataObj.classRoomInfo = k.list[i].weeklyCourseList;
                    data.push(dataObj);
                  });
                  tabObj.data = data;
                }

                //复制表格数据
                this.tableArr = initTabArr;

                //创建导出表格的数据
                let exportArr = [];
                serveData.forEach((i) => {
                  let list = i.list;
                  list.forEach((k) => {
                    let weeklyCourseList = k.weeklyCourseList;
                    if (!this.isEmpty(weeklyCourseList)) {
                      weeklyCourseList.forEach((m) => {
                        let obj = new Object();
                        //星期
                        obj.weekDay = i.weekDay;
                        // 时段
                        obj.timeName = k.timeName;
                        //教室
                        obj.roomName = m.roomName;
                        //班级
                        obj.className = m.className;
                        //班级课程
                        obj.curriculumName = m.curriculumName;
                        //班级人数
                        obj.num = m.classNum + "/" + m.planNum;

                        //老师
                        obj.teacherName = m.teacherName;

                        // 状态
                        if (m.state == 0) {
                          obj.stateValue = "已排未上";
                        } else {
                          obj.stateValue = "已排已上";
                        }

                        exportArr.push(obj);
                      });
                    }
                  });
                });

                //赋值导出数组
                this.exportArr = exportArr;
                // console.log("导出数组是:", exportArr);
              }
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    //导出表格的方法
    exportTable() {
      if (this.isEmpty(this.exportArr)) {
        this.showWarnMsg("暂无数据,无需导出");
        return;
      }
      require.ensure([], () => {
        //定义表头
        const tHeader = this.tHeaderArr;
        //定义表格数据的可想的属性key值
        const filterVal = this.tBodyValue;
        const data = this.formatJson(filterVal, this.exportArr);
        //导出操作最后一个是表名字
        export_json_to_excel(tHeader, data, `${this.startTime}_周课程表_时段`);
      });
      //发送导出消息
      this.httpMsg();
    },

    //导出的时候请求服务器
    httpMsg() {
      let param = this.getHttpParams();
      param.type = 17;
      this.$http.fetchPost(this.$api.LESSONMANAGER, param).then((res) => {
        //只用请求服务器,不需要做任何操作
      });
    },

    //格式化json的方法
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
  },
  watch: {
    startTime(newValue) {
      if (!this.isEmpty(newValue)) {
        //重新刷新页面数据
        this.httpData();
      }
    },
  },
  props: ["startTime"],
};
</script>

<style lang="scss" scoped>
.custom_table_with_time {
  width: 100%;
  margin: 0 auto;
  display: flex;
  min-height: 380px;
  align-items: center;
  flex-direction: column;

  .custom_table_with_time_content {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  ::v-deep .el-table .cell {
    padding: 0;
    line-height: 48px;
  }
  ::v-deep .el-table .el-table__cell {
    padding: 0;
  }

  //自定义的表格内容
  .custom_tab_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    .item_table {
      display: flex;
      flex-direction: column;
      font-size: 12px;

      div {
        text-align: left;
        margin-left: 14px;
      }
    }

    //底边距
    .has_bom {
      margin-bottom: 10px;
    }

    //已上
    .has_bg {
      background: #b3e6ff;
    }

    //未上的背景色
    .no_bg {
      background: #fec433;
    }
  }

  //底部的排课信息展示区域
  .bom_info {
    width: 100%;
    display: flex;
    margin-top: 30px;
    align-items: center;

    .com_dv {
      width: 20px;
      height: 20px;
      border-radius: 4px;
    }

    .color_1 {
      background: #b3e6ff;
    }

    .color_2 {
      background: #fec433;
    }

    .color_3 {
      background: #cfd2cf;
    }
  }

  //div一行显示
  .one_line {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 110px;
  }

  .l_h {
    line-height: 30px;
  }

  ::v-deep .loading_view {
    margin-top: 150px;
  }
}
</style>
