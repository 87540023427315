//周课表
<template>
  <div class="week_lessons">
    <!-- 视图部分 -->
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <div>
          <!-- 顶部操作栏 -->
          <div class="top_action">
            <!-- 上课日期 -->
            <div class="class_time margin_right_14">
              <span class="margin_right_10">上课日期</span>

              <!-- 日期选择 -->
              <el-date-picker
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                v-model="dateValue"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>

            <!-- 导出 -->
            <el-button
              :loading="showExporLoading"
              type="primary"
              size="medium"
              @click.stop="doExport"
            >
              导&nbsp;&nbsp;出</el-button
            >
          </div>

          <!-- 时段和教室查看 -->
          <div>
            <TopNavBar :tabArr="tabArr" @onItemClick="onItemClick"></TopNavBar>
          </div>

          <!-- 排课信息展示表格-按时段 -->
          <CustomTableWithTime
            :startTime="dateValue"
            ref="c_t_w_t"
            v-if="currPos == 0"
          ></CustomTableWithTime>

          <!-- 排课信息展示表格-按教室 -->
          <CustomTableWithRoom
            :startTime="dateValue"
            ref="c_t_w_r"
            v-if="currPos == 1"
          ></CustomTableWithRoom>
        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
//按时段查看的表格
import CustomTableWithTime from "@/components/table/CustomTableWithTime.vue";
//按教室查看的表格
import CustomTableWithRoom from "@/components/table/CustomTableWithRoom.vue";

export default {
  name: "weekLessons",
  data() {
    return {
      //是否加载视图标识
      showLoading: 1,

      //时段和教室的选中位置
      currPos: 0,

      //日期选择器的值
      dateValue: "",

      //日期选择的配置项
      pickerOptions: {
        //设置禁用日期,2022年1月1日之前的不允许选择,最多选择到当前日期
        disabledDate(time) {
          let dates = new Date("2021-12-31");
          // || time.getTime() >= new Date().getTime()
          return time.getTime() <= dates.getTime();
        },
      },

      //顶部切换按钮
      tabArr: ["按时段查看", "按教室查看"],

      //导出的加载框
      showExporLoading: false,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    //初次进入的时候初始化日期的值,默认加载当天的日期
    initData() {
      this.dateValue = this.formatDate(new Date(), "yyyy-MM-dd");
    },
    //时段和教室查看的点击事件
    onItemClick(pos) {
      this.currPos = pos;
    },
    //导出PDF
    doExport() {
      //原定的导出PDF的方法
      this.showExporLoading = true;

      // downloadPDF(document.getElementById("pdf"));
      if (this.currPos == 0) {
        //按时段导出
        this.$refs.c_t_w_t.exportTable();
      } else if (this.currPos == 1) {
        //按教室导出
        this.$refs.c_t_w_r.exportTable();
      }

      //请求一下服务器同步一下导出信息
      // this.httpMsg();

      setTimeout(() => {
        this.showExporLoading = false;
      }, 2000);
    },

    //导出的时候请求服务器
    httpMsg() {
      let param = this.getHttpParams();
      param.type = 16;
      this.$http.fetchPost(this.$api.LESSONMANAGER, param).then((res) => {
        //只用请求服务器,不需要做任何操作
      });
    },
  },
  computed: {},
  components: {
    CustomTableWithTime,
    CustomTableWithRoom,
  },
};
</script>
<style lang="scss" scoped>
.week_lessons {
  margin: 0 14px;
  height: calc(100vh - 78px - 56px);
  display: flex;
  flex-direction: column;

  //顶部操作栏
  .top_action {
    height: 50px;
    display: flex;
    align-items: center;

    .class_time {
      display: flex;
      align-items: center;

      ::v-deep .el-input__inner {
        height: 32px;
        line-height: 32px;
      }

      ::v-deep .el-date-editor.el-input {
        width: 160px;
      }

      ::v-deep .el-input__icon {
        line-height: 30px;
      }
    }

    .commit {
      height: 32px;
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #409eff;
      color: white;
    }
  }
}
</style>
